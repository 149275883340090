import { useTranslation } from "react-i18next";
import { Image, type Props as ImageProps } from "~/components/Image";

export interface Props extends ImageProps {}

/**
 * Just like <Image />, but with a fallback image and alt text.
 */
const ProductImage: React.FC<Props> = ({ media, ...props }) => {
  const { t } = useTranslation();
  return (
    <Image
      {...props}
      media={media}
      fallbackSrc="/img/noImage.svg"
      alt={media?.alt ?? t("product.image.noImageAlt")}
    />
  );
};
export default ProductImage;
