import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

export interface FreeShippingBadgeProps
  extends React.HTMLAttributes<HTMLDivElement> {
  freeShipping: boolean;
}

const FreeShippingBadge: React.FC<FreeShippingBadgeProps> = ({
  freeShipping,
  className,
  ...props
}) => {
  const { t } = useTranslation();
  return freeShipping ? (
    <div
      {...props}
      className={twMerge(
        "text-blu-500 w-fit cursor-default rounded-md bg-gray-100 px-2 py-0.5 text-xs font-medium",
        className,
      )}
    >
      {t("product.freeShippingBadge")}
    </div>
  ) : null;
};
export default FreeShippingBadge;
