import React from "react";
import type { MediaFragmentType } from "~/graphql/fragments/types";
import getOptimizedImageUrl from "~/util/getOptimizedImageUrl";

/**
 * srcSet can either be used natively or by passing an array of  { options: cloudflare transformation options, width: css width }
 * e.g. { options: { width: 400, quality: 80 }, width: "500w" }
 */
export interface Props
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, "srcSet"> {
  media: MediaFragmentType | null;
  srcSet?: { options: object; size: string }[] | string;
  fallbackSrc?: string;
}

export const Image: React.FC<Props> = ({
  media,
  fallbackSrc,
  srcSet,
  src,
  alt,
  width,
  height,
  ...props
}) => {
  // use src, alt, width and height from image if provided
  src ||= media?.url || fallbackSrc || undefined;
  alt ||= media?.alt || undefined;
  width ||= media?.width || undefined;
  height ||= media?.height || undefined;

  // transform srcSet array to string
  if (typeof srcSet === "object") {
    srcSet = srcSet
      .map((item) => {
        return `${getOptimizedImageUrl(src || "", item.options)} ${item.size}`;
      })
      .join(", ");
  }

  return (
    <img
      {...props}
      src={src}
      alt={alt}
      width={width}
      height={height}
      srcSet={srcSet}
    />
  );
};

export default Image;
