import { twMerge } from "tailwind-merge";
import { formatPrice } from "~/util/format";

export interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  value: number;
  zero?: string | React.ReactNode; // display this string if the value is 0
}

const Price: React.FC<Props> = ({ value, zero, className, ...props }) => {
  const isZero = value === 0 || isNaN(value);
  zero = zero ?? formatPrice(0);

  return isZero ? (
    <>{zero}</>
  ) : (
    <span {...props} className={twMerge("font-numbers", className)}>
      {formatPrice(value)}
    </span>
  );
};
export default Price;
